// Here you can add other styles

.btn:focus,
.btn.focus {
	outline: 0;
	box-shadow: none;
}

.btn:hover {
	i {
		color: #20a8d8;
	}
}

.btn-xs {
	transform: scale(0.7);
	transform-origin: left;
}

.modal {
	.modal-body {
		min-height: 70vh;
		height: 100%;
		.formSection {
			height: 100%;
			form {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
		}
	}
	&.auto-height {
		.modal-body {
			min-height: auto;
		}
	}
}

.avatar > img {
	height: inherit;
	object-fit: cover;
	width: 100%;
}

.courseIntroduction {
	max-width: 100px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.spinner {
	top: 50%;
	left: 50%;
	z-index: 1;
	display: flex;
	position: fixed;
	align-items: center;
	justify-content: center;
	transform: translate(-50%, -50%);
	img {
		width: 100px;
		height: 100px;
	}
}

.closeIcon {
	position: absolute;
	right: 1rem;
	top: 1rem;
	width: 22px;
	height: 22px;
	opacity: 0.8;
	button {
		z-index: 1;
		border: none;
		outline: none;
		width: inherit;
		height: inherit;
		cursor: pointer;
		position: absolute;
		background: transparent;
	}
	&:hover {
		opacity: 1;
	}
	&:before,
	&:after {
		position: absolute;
		left: 10px;
		content: ' ';
		height: inherit;
		width: 2px;
		background-color: #333;
	}
	&:before {
		transform: rotate(45deg);
	}
	&:after {
		transform: rotate(-45deg);
	}
}

a {
	&:hover {
		text-decoration: none;
	}
}

.cursor-pointer {
	cursor: pointer;
}

td {
	white-space: nowrap;
	max-width: 200px;
	text-overflow: ellipsis;
	overflow: hidden;
}

.row.column-reverse {
	flex-direction: column-reverse;
}
.row.row-reverse {
	flex-direction: row-reverse;
}
.row.flex-end {
	justify-content: flex-end;
}

.ql-toolbar,
.ql-container {
	border-color: #e4e7ea !important;
	border-radius: 0.25rem !important;
}

.invalid {
	border: 1px solid #f86c6b;
	border-radius: 0.25rem;
	.ql-toolbar,
	.ql-container {
		border: none !important;
	}
}

.tabContent {
	margin-top: -2px;
}

button:disabled {
	cursor: not-allowed;
}

.selectFile {
	input {
		display: none;
	}
	button {
		padding: 0;
		label {
			margin: 0;
			cursor: pointer;
			padding: 0.375rem 0.75rem;
		}
	}
	span {
		margin: 0 0 0 10px;
	}
}

.flexSpaceBetween {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.h5,
	p {
		margin: 0;
	}
	&.base {
		align-items: baseline;
	}
}

.flexCenter {
	display: flex;
	align-items: center;
	justify-content: center;
}

.app-header .nav-item .nav-link .img-avatar,
.app-header .nav-item .avatar.nav-link img {
	width: 35px;
	height: 35px;
}

.error {
	color: red;
	margin: 10px 0;
}
.success {
	color: green;
	margin: 10px 0;
}

.min-height-100 {
	min-height: 100vh;
}

.form-group {
	align-items: center;
}

.form-group.col {
	flex-direction: column;
}

.modal-xlg {
	width: 90%;
	max-width: 90%;
}

.addbutton {
	margin-top: 1rem;
	width: max-content;
}

.deleteButton {
	align-self: center;
	height: max-content;
	margin-bottom: 1rem;
}

.form-group.sleek {
	padding: 0 15px;
	.addbutton {
		margin-top: 0;
		margin-left: 1rem;
	}
	& > div,
	.deleteButton {
		margin-bottom: 0;
	}
}

.col.sleek,
.col.sleek-xs,
.col.nonsleek {
	margin: 0;
	padding: 0 8px;
	[class*='col-'] {
		padding: 0;
		width: 100%;
		max-width: 100%;
		overflow: hidden;
	}
}

.col.sleek {
	max-width: 150px;
}

.col.sleek-xs {
	max-width: 100px;
}

.hidden {
	opacity: 0;
	visibility: hidden;
}

.highlight {
	background: rgba(#20a8d8, 0.4);
}

.sidebar .nav-link {
	padding: 0.35rem 1rem;
}

@media (min-width: 768px) {
	.form-group label {
		margin-bottom: 0;
	}
}

.customTaxInput {
	padding: 4px;
	display: flex;
	border-radius: 4px;
	border: 1px solid #e4e7ea;
	.form-group {
		margin: 0;
		input {
			padding: 0;
		}
	}
	input,
	select {
		border: none;
		box-shadow: none !important;
	}
	select {
		width: 120px;
		outline: none;
		color: #20a8d8;
		font-weight: bold;
		background-color: lighten(#20a8d8, 40);
		&:focus {
			color: #20a8d8;
			background-color: lighten(#20a8d8, 40);
		}
	}
}

/* Kendo calendar styles start here */
.k-animation-container {
	z-index: 9999;
}

.k-dateinput-wrap input.k-input {
	@extend .form-control;
}

span.k-picker-wrap {
	span.k-icon {
		height: 100%;
		cursor: pointer;
		margin-left: 5px;
	}
}

.k-calendar-navigation span.k-calendar-navigation-marker {
	font-weight: normal;
}

.form-calendarpicker .k-dateinput {
	width: 100%;
}
/* Kendo calendar styles end here */
