// Variable overrides
$themePrimaryColor: #48b848;
$themeGrayColor: #ececec;

$blueColor: #2db1ff;
$orangeColor: #eb6308;
$greenColor: #43da41;

$taal: #e31d19;
$rekenen: #48b848;
$motoriek: #00b1ff;
$generic: #777;

$lenteSeason: #fec900;
$zomerSeason: #64b950;
$winterSeason: #71529e;
$herfstSeason: #fc932a;

$lightbackground: #f9f9f9;

$headingcolor: #333;

$textDarkColor: #333;

$darkBgColor: #222;

$textColor: #666;

$disableTextColor: #999;

$white: #fff;

$white-gray: #f1f1f1;

$light-gray: #f5f5f5;

$placeholderColor: #f6f6f6;
