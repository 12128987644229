.pagination {
	margin: 20px auto;
	width: max-content;
	> li {
		display: inline;
		cursor: pointer;
		> a {
			position: relative;
			float: left;
			padding: 6px 12px;
			margin-left: -1px;
			line-height: 1.42857143;
			color: #20a8d8;
			text-decoration: none;
			background-color: #fff;
			border: 1px solid #ddd;
			&:focus {
				z-index: 2;
				color: #23527c;
				background-color: #eee;
				border-color: #ddd;
			}
			&:hover {
				z-index: 2;
				color: #23527c;
				background-color: #eee;
				border-color: #ddd;
			}
		}
		> span {
			position: relative;
			float: left;
			padding: 6px 12px;
			margin-left: -1px;
			line-height: 1.42857143;
			color: #20a8d8;
			text-decoration: none;
			background-color: #fff;
			border: 1px solid #ddd;
			&:focus {
				z-index: 2;
				color: #23527c;
				background-color: #eee;
				border-color: #ddd;
			}
			&:hover {
				z-index: 2;
				color: #23527c;
				background-color: #eee;
				border-color: #ddd;
			}
		}
		&:first-child {
			> a {
				margin-left: 0;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}
			> span {
				margin-left: 0;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}
		}
		&:last-child {
			> a {
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}
			> span {
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		}
	}
	> .active {
		> a {
			z-index: 3;
			color: #fff;
			cursor: default;
			background-color: #20a8d8;
			border-color: #20a8d8;
			&:focus {
				z-index: 3;
				color: #fff;
				cursor: default;
				background-color: #20a8d8;
				border-color: #20a8d8;
			}
			&:hover {
				z-index: 3;
				color: #fff;
				cursor: default;
				background-color: #20a8d8;
				border-color: #20a8d8;
			}
		}
		> span {
			z-index: 3;
			color: #fff;
			cursor: default;
			background-color: #20a8d8;
			border-color: #20a8d8;
			&:focus {
				z-index: 3;
				color: #fff;
				cursor: default;
				background-color: #20a8d8;
				border-color: #20a8d8;
			}
			&:hover {
				z-index: 3;
				color: #fff;
				cursor: default;
				background-color: #20a8d8;
				border-color: #20a8d8;
			}
		}
	}
	> .disabled {
		> a {
			color: #777;
			cursor: not-allowed;
			background-color: #fff;
			border-color: #ddd;
			&:focus {
				color: #777;
				cursor: not-allowed;
				background-color: #fff;
				border-color: #ddd;
			}
			&:hover {
				color: #777;
				cursor: not-allowed;
				background-color: #fff;
				border-color: #ddd;
			}
		}
		> span {
			color: #777;
			cursor: not-allowed;
			background-color: #fff;
			border-color: #ddd;
			&:focus {
				color: #777;
				cursor: not-allowed;
				background-color: #fff;
				border-color: #ddd;
			}
			&:hover {
				color: #777;
				cursor: not-allowed;
				background-color: #fff;
				border-color: #ddd;
			}
		}
	}
}

.pagination-lg {
	> li {
		> a {
			padding: 10px 16px;
			font-size: 18px;
			line-height: 1.3333333;
		}
		> span {
			padding: 10px 16px;
			font-size: 18px;
			line-height: 1.3333333;
		}
		&:first-child {
			> a {
				border-top-left-radius: 6px;
				border-bottom-left-radius: 6px;
			}
			> span {
				border-top-left-radius: 6px;
				border-bottom-left-radius: 6px;
			}
		}
		&:last-child {
			> a {
				border-top-right-radius: 6px;
				border-bottom-right-radius: 6px;
			}
			> span {
				border-top-right-radius: 6px;
				border-bottom-right-radius: 6px;
			}
		}
	}
}
